import {withSession} from '@/recharge-storefront/client';
import {logError, logInfo} from '@/utilities/log';
import {withDeduplication} from '@/utilities/with-deduplication';
import {batch, signal} from '@preact/signals';
import {Plan, getPlan, listPlans} from '@rechargeapps/storefront-client';

const productSellingPlans = signal<Map<string, Plan[]>>(new Map());
const sellingPlans = signal<Map<string, Plan>>(new Map());

/**
 * Returns the selling plans for a product. If the selling plans are not yet
 * loaded, they will be loaded asynchronously.
 * Can be subscribed to using preact signals to be notified when the selling
 * plans are loaded.
 * @param productId - the ID of the product
 * @returns the selling plans if they are already loaded, or an empty array if
 * they are not yet
 * @example Usage in vanilla TS
 * ```ts
 * import {getProductSellingPlans} from '@/selling-plan/selling-plan-store';
 * import {effect} from '@preact/signals';
 *
 * // Subscribe to selling plans in vanilla TS
 * effect(() => {
 *  const plans = getProductSellingPlans('gid://shopify/Product/123');
 * if (plans.length) {
 *  // do something with the selling plans
 * });
 * ```
 *
 * @example Usage in a component
 * ```tsx
 * import {getProductSellingPlans} from '@/selling-plan/selling-plan-store';
 *
 * // Subscribe to selling plans in JSX
 * export default function MyComponent({productId}: {productId: number}) {
 *  // the component will re-render when the selling plans are loaded
 * const plans = getProductSellingPlans(productId);
 * // we can display a placeholder while the plans are empty
 * return plans.length ? <div>{plans[0].name}</div> : <div>Loading...</div>;
 * }
 * ```
 */
export function getProductSellingPlans(productId: string): Plan[] {
  const plans = productSellingPlans.value.get(productId);
  if (!plans) {
    const handleError = (error: Error) => {
      logError(error, {
        productId,
      });
    };
    withDeduplication(productId, () =>
      loadProductSellingPlans(productId)
    ).catch(handleError);
    return [];
  }
  // Only return the plans that are available on the storefront
  // This ensures that the Recharge product settings are respected
  const availablePlans = Array.from(
    plans.filter(({channel_settings}) => channel_settings.checkout_page.display)
  );

  return availablePlans;
}

/**
 * Sets the selling plans for a specific product.
 *
 * @param productId - The ID of the product.
 * @param plans - An array of selling plans to be set for the product.
 */
export function setProductSellingPlans(productId: string, plans: Plan[]) {
  const newMap = new Map(productSellingPlans.value);

  // Deduplicate the plans to avoid unnecessary updates
  if (JSON.stringify(newMap.get(productId)) === JSON.stringify(plans)) {
    return;
  }

  newMap.set(productId, plans);

  batch(() => {
    productSellingPlans.value = newMap;
    mapSellingPlans(plans);
  });
  logInfo('SetRechargeProductSellingPlans', {
    productId,
    plans,
  });
}

/**
 * Returns a selling plan by its ID. If the selling plan is not yet loaded, it
 * will be loaded asynchronously.
 * Can be subscribed to using preact signals to be notified when the selling
 * plan is loaded.
 * @param sellingPlanId - the ID of the selling plan
 * @returns the selling plan if it is already loaded, or null if it is not yet
 *
 * @example Usage in vanilla TS
 * ```ts
 * import {getSellingPlan} from '@/selling-plan/selling-plan-store';
 * import {effect} from '@preact/signals';
 *
 * // Subscribe to selling plan in vanilla TS
 * effect(() => {
 * const plan = getSellingPlan('gid://shopify/SellingPlan/123');
 * if (plan) {
 * // do something with the selling plan
 * });
 * ```
 *
 * @example Usage in a component
 * ```tsx
 * import {getSellingPlan} from '@/selling-plan/selling-plan-store';
 *
 * // Subscribe to selling plan in JSX
 * export default function MyComponent({sellingPlanId}: {sellingPlanId: number}) {
 * // the component will re-render when the selling plan is loaded
 * const plan = getSellingPlan(sellingPlanId);
 * // we can display a placeholder while the plan is null
 * return plan ? <div>{plan.name}</div> : <div>Loading...</div>;
 * }
 * ```
 */
export function getSellingPlan(sellingPlanId: string) {
  const plan = sellingPlans.value.get(sellingPlanId);
  if (!plan) {
    const handleError = (error: Error) => {
      logError(error, {
        sellingPlanId,
      });
    };
    withDeduplication(sellingPlanId, () =>
      loadSellingPlan(sellingPlanId)
    ).catch(handleError);
    return null;
  }
  // Only return the plan if it is available on the storefront
  // This ensures that the Recharge product settings are respected
  const isAvailable = plan.channel_settings.checkout_page.display;
  return isAvailable ? plan : null;
}

/**
 * Loads the selling plans for a product.
 * This is not meant to be called directly. Use `getProductSellingPlans` instead.
 * @param productId - the ID of the product
 * @returns a promise that resolves when the selling plans are loaded
 */
async function loadProductSellingPlans(productId: string) {
  try {
    const response = await withSession((session) =>
      listPlans(session, {
        external_product_id: productId,
      })
    );
    const plans = response?.plans ?? [];

    // We expect at least one selling plan for each product,
    // so we throw an error if none are found
    if (plans.length === 0) {
      throw new Error('No selling plans found for product');
    }

    setProductSellingPlans(productId, plans);
    logInfo('LoadedRechargeSellingPlans', {
      productId,
      plans,
    });
  } catch (error) {
    logError(error, {message: 'ErrorLoadingRechargeSellingPlans', productId});
  }
}

/**
 * Loads a selling plan by its ID.
 * This is not meant to be called directly. Use `getSellingPlan` instead.
 * @param sellingPlanId - the ID of the selling plan
 * @returns a promise that resolves when the selling plan is loaded
 *
 */
async function loadSellingPlan(sellingPlanId: string) {
  const plan = await withSession((session) => getPlan(session, sellingPlanId));
  mapSellingPlans([plan]);
}

/**
 * Maps selling plans to the sellingPlans signal
 * @param plans - the selling plans to map
 */
function mapSellingPlans(plans: Plan[]) {
  const newMap = new Map(sellingPlans.value);
  for (const plan of plans) {
    newMap.set(plan.id.toString(), plan);
  }
  sellingPlans.value = newMap;
}
